//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ctrlOrder from "@/controller/order.js";
import { Message, Radio, RadioGroup, Image, Button, Link } from "element-ui";
import Payway from "@/components/Payway";
export default {
    components: {
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio,
        [Image.name]: Image,
        [Button.name]: Button,
        [Link.name]: Link,
        Payway,
    },
    // 離開路由時判斷
    beforeRouteLeave(to, from, next) {
        //停止計時器
        clearInterval(this.dt);

        next();
    },
    data() {
        return {
            radioChange: "", //付款方式
            orderDtl: {}, //訂單詳情
            submitbar: false,
        };
    },
    created() {
        //訂單明細
        this.getDetail();
    },
    computed: {
        disc() {
            if (this.Common.isExist(this.orderDtl.SoInfo)) {
                let amtDisc = 0;
                let amtPromo = 0;
                if (this.Common.isExist(this.orderDtl.SoInfo.amtDisc)) amtDisc = Number(this.orderDtl.SoInfo.amtDisc);
                if (this.Common.isExist(this.orderDtl.SoInfo.amtPromo)) amtPromo = Number(this.orderDtl.SoInfo.amtPromo);

                return amtDisc + amtPromo;
            }
        },
    },
    watch: {},
    methods: {
        //獲取訂單明細
        async getDetail() {
            this.orderDtl = await ctrlOrder.getDetail(this.$route.params);
            console.log(this.orderDtl);
        },
        getSubmitbar(item) {
            this.submitbar = item;
        },
    },
};
